import logo from './logo.svg';
import './styles/sass/App.scss';
import React,{Suspense, lazy} from 'react';

import {BrowserRouter as Router, Switch, NavLink, Route, Redirect} from "react-router-dom";
import { Position, Toast, Toaster, Classes, Alert, Intent} from "@blueprintjs/core";
import CircleLoader from './components/loaders/CircleLoader';

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const Landing = lazy(() => import("./components/Landing"));

class App extends React.Component {
  _mounted;

  constructor(props){
    super(props);

    this.state = {
      toasts:[]
    }

    this.toaster = {};
        this.refHandlers = {
            toaster:(ref) => {this.toaster = ref},
        }
  }

  componentDidMount(){
    this._mounted = true;
  }

  componentWillUnmount(){
    this._mounted = false;
  }

  addToast = (message) => {
    this.toaster.show({ message: message});
}

  render(){
    return <div className="app">
      
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {

                    }, text:"Resend"}} {...toast} />)}
            </Toaster>
        <Suspense fallback={<CircleLoader/>}>
          <Router>
          <Navbar/>
            <Switch>
              <Route exact={true} path="/" render={() => <Landing addToast={this.addToast}/>}/>
            </Switch>
            <Footer/>
          </Router>
        </Suspense>
    </div>
  }
}

export default App;
